import React, { SyntheticEvent } from 'react'
import classNames from 'classnames'
import { parse } from 'query-string'
import { NavLink } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { Store } from 'react-notifications-component'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { CatalogItem } from '../../store/catalog/types'
import { ICategory } from '../../store/departments/types'
import priceToString from '../../utils/PriceToString'
import noPhoto from '../../assets/images/no-photo.svg'
import Loader from '../loader/Loader'
import { decodeHtmlSymbols } from '../../utils/DecodeHtmlSymbols'
import { incrementCartItem, decrementCartItem } from '../../store/cart/actions'
import { addToFavorites, removeFromFavorites } from '../../store/favorites/actions'
import { fetchFavorites } from '../../store/account/favorites/action-creators'
import { ReduxState } from '../../store/reducers'

export interface IProductDiscount {
  required_count: number
  gift_product: CatalogItem
  gift_count: number
  percent_value: number
  cash_value: number
}

export interface ICatalogItemComponent extends CatalogItem {
  loading?: boolean
  discounts?: IProductDiscount[]
  category: ICategory[]
  storeId?: number | undefined
  mode?: string
  routeState?: object
  isAsapDelivery: boolean
}

const CatalogItemComponent: React.FC<ICatalogItemComponent> = ({
  id,
  name,
  real_sale_price,
  sale_price,
  images,
  loading = false,
  discounts,
  isFavorites,
  cardCount,
  out_of_stock,
  category,
  storeId,
  mode = '',
  routeState,
  isAsapDelivery
}) => {
  const dispatch = useDispatch()
  const params = parse(window.location.search)

  const incrementCount = async (e: SyntheticEvent) => {
    e.preventDefault()

    try {
      await dispatch(incrementCartItem({ id, storeId }))
    } catch (e) {
      console.warn(e)
    } finally {
      Store.addNotification({
        title: 'Cart',
        message: 'Product add to cart!',
        type: 'info',
        insert: 'top',
        container: 'bottom-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 1000,
          onScreen: true
        }
      })
    }
  }

  const decrementCount = async (e: SyntheticEvent) => {
    e.preventDefault()

    try {
      if (cardCount === 0) {
        return
      }
      await dispatch(decrementCartItem({ id, storeId }))
    } catch (e) {
      console.warn(e)
    }
  }

  const toggleFavorites = async (e: SyntheticEvent) => {
    e.preventDefault()
    try {
      if (isFavorites) {
        await dispatch(removeFromFavorites(id))
      } else {
        await dispatch(addToFavorites(id))
      }
      dispatch(fetchFavorites())
    } catch (e) {

    }
  }

  const discountText = discounts && discounts[0]
    ? discounts[0].percent_value
        ? `${discounts[0].percent_value}%`
        : discounts[0].cash_value
          ? `${priceToString(discounts[0].cash_value)}`
          : null
    : null

  const slicedTitle = decodeHtmlSymbols(name).slice(0, 40)
  return (
    loading
      ? <div key={id} className={classNames('catalog__item', 'loading', mode)}>
          <div className="catalog__item-image">
            <Loader color="blue" className='product-item'/>
          </div>
          <div></div>
          <div className="catalog__item-placeholders catalog__item-placeholders-title">
            <div></div>
          </div>
          <div className="catalog__item-placeholders catalog__item-placeholders-price">
            <div></div>
          </div>
          <div className="catalog__item-placeholders catalog__item-placeholders-nav">
            <div></div>
            <span></span>
          </div>
        </div>
      : <NavLink
            className={classNames('catalog__item', mode)} key={id}
            to={{ pathname: params.store ? `/catalog/${params.store}/${id}` : `/catalog/${id}`, state: routeState }}
          >
          {category && category.length > 0 ? <span className="catalog__item-category">{category[0].title}</span> : null}
          <span className="catalog__item-title">{decodeHtmlSymbols(name).length > slicedTitle.length ? `${slicedTitle}...` : decodeHtmlSymbols(name)}</span>
          <span className={classNames('catalog__item-price', { red: discountText })}>{priceToString(real_sale_price)}</span>{
            sale_price !== real_sale_price && <span className="catalog__item-old"><span className="catalog__item-old-price">{priceToString(sale_price)}</span><span className="catalog__item-old-discount">{`Discount ${discountText}`}</span></span>
          }
          <div className="catalog__item-image"><LazyLoadImage alt={name} src={ images && images[0] ? images[0].url : noPhoto } /></div>
          {
            out_of_stock
              ? <><span className="catalog__item-ended">
                  <span className = "catalog__item-ended-text">Out of Stock</span>
                </span>
                <div className="form__counter-container"><button className="form__counter button disabled" disabled>
                    <span>Out of stock</span>
                  </button></div></>
              : cardCount === 0
                  ? <div className="form__counter-container"><button className="form__counter button" onClick={incrementCount}>
                      <span>Add to cart</span>
                    </button></div>
                  : <div className="form__counter-container form__counter-container--centered"><div className="form__counter card">
                      <span className="form__counter-decrement card" onClick={decrementCount}></span>
                      <input className="form__input form__input--big form__input--centered card" type="text" value={`${cardCount} pcs`} disabled />
                      <span className="form__counter-increment card" onClick={incrementCount}></span>
                    </div></div>
          }
          <div className={classNames('catalog__item-add-to-favorites', { active: isFavorites })}
            onClick={toggleFavorites} title={isFavorites ? 'Remove from favorites' : 'Add to favorites'}></div>
        </NavLink>
  )
}

export default connect(
  (state: ReduxState) => ({
    isAsapDelivery: false
  })
)(CatalogItemComponent)
